<template>
  <v-container>
    <v-form ref="conf_form">
      <div class="titles mt-6">Documents</div>
      <v-divider class="mb-3"></v-divider>
      <v-card
        outlined
        class="mb-8"
        :style="cardStyle"
        v-for="(document, k) in configurations.venue_service_documents"
        :key="'d_' + k"
      >
        <v-btn
          v-if="k == 0"
          small
          @click="gotoMembership"
          absolute
          top
          right
          style="top: -50px; margin-right: -1%"
        >
          <v-icon small>mdi-backburger</v-icon>Back
        </v-btn>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                label="Name"
                required
                outlined
                background-color="#fff"
                v-model="document.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="document.document_type_id"
                :items="documentTypes"
                item-text="name"
                item-value="id"
                label="Type"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-file-input
                :label="document.id != null ? '' : 'Document'"
                v-model="document.file"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon
                outlined
                background-color="#fff"
              >
                <template v-slot:label>
                  <span v-if="!document.id"> Select file </span>
                  <span
                    v-if="document.id && !document.file"
                    class="font-weight-bold"
                  >
                    <span
                      style="width: 70%; display: inline-block"
                      class="text-truncate"
                      >{{ document.original_file_name }}</span
                    >
                    <span
                      style="width: 20%; display: inline-block"
                      class="text-truncate"
                      >.{{
                        document.original_file_name.split(".")[
                          document.original_file_name.split(".").length - 1
                        ]
                      }}</span
                    >
                  </span>
                </template>
              </v-file-input>
            </v-col>
            <v-col md="1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="
                      checkDeletePermission($modules.facility.management.slug)
                    "
                    v-bind="attrs"
                    v-on="on"
                    color="red"
                    class="mt-2"
                    @click="deleteDocuments(k)"
                    fab
                    x-small
                    dark
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="add_btn">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="teal-color"
              fab
              x-small
              dark
              @click="addDocuments"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          Add Document
        </v-tooltip>
      </div>
      <div class="titles mt-2">Booking Configurations</div>
      <v-divider></v-divider>
      <v-card class="mb-8" :style="cardStyle">
        <v-row>
          <v-col>
            <table class="settings_table">
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>View</th>
                  <th>Mandatory</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(config, index) in configurations.field_configurations"
                  :key="index"
                >
                  <td>{{ config.name }}</td>
                  <td>
                    <v-btn
                      icon
                      :color="config.is_visible == 1 ? 'success' : 'error'"
                      @click="checkAsVisible(index)"
                    >
                      <v-icon
                        :style="
                          viewRestrictedFields.includes(config.slug) &&
                          'cursor:not-allowed;'
                        "
                      >
                        {{ config.is_visible == 1 ? "mdi-check" : "mdi-close" }}
                      </v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      icon
                      :color="config.is_required == 1 ? 'success' : 'error'"
                      @click="checkAsMandatory(index)"
                    >
                      <v-icon
                        :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                          'cursor:not-allowed;'
                        "
                      >
                        {{
                          config.is_required == 1 ? "mdi-check" : "mdi-close"
                        }}
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2 white--text teal-color"
          text
          height="45"
          @click="saveGroundSettings()"
          >Save</v-btn
        >
      </v-card-actions>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";

export default {
  data() {
    return {
      configurations: {
        field_configurations: [],
        venue_service_documents: [],
        venue_service_configuration: {},
        deleted_service_documents: [],
        game_formations: [{ name: "" }],
        facility_surface: [{}],
      },
      venueServiceId: null,
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  mounted() {
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    this.getConfiguration();
  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
  },
  methods: {
    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
          field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
          field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    addDocuments() {
      this.configurations.venue_service_documents.push({
        name: null,
        file: null,
        document_type_id: null,
      });
    },
    deleteDocuments(index) {
      if (
        !this.configurations.venue_service_documents[index].name &&
        !this.configurations.venue_service_documents[index].id &&
        !this.configurations.venue_service_documents[index].document_type_id
      ) {
        this.configurations.venue_service_documents.splice(index, 1);
        if (!this.configurations.venue_service_documents.length) {
          this.configurations.venue_service_documents = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
    confirmActions(data) {
      if (data.type == "delete_document") {
        if (this.configurations.venue_service_documents[data.id].id != null) {
          this.configurations.deleted_service_documents.push(
            this.configurations.venue_service_documents[data.id].id
          );
        }
        this.configurations.venue_service_documents.splice(data.id, 1);
        if (!this.configurations.venue_service_documents.length) {
          this.configurations.venue_service_documents = [{}];
        }
      }

      this.confirmModel.id = null;
    },
    getConfiguration() {
      this.showLoader();
      this.$store
        .dispatch("loadConfigurations", "Academy")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.configurations = response.data.data;
            if (this.configurations.venue_service_documents.length == 0) {
              this.configurations.venue_service_documents.push({
                document_type_id: "",
                name: "",
              });
            }
            this.configurations.deleted_service_documents = [];

            this.$forceUpdate();
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    saveGroundSettings() {
      if (!this.$refs.conf_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      formData.append(`product_type`, "Membership");
      this.configurations.field_configurations.forEach((item, index) => {
        for (let key in item) {
          if (item[key] != null) {
            formData.append(
              `field_configurations[${index}][${key}]`,
              item[key]
            );
          }
        }
      });
      if (this.configurations.deleted_service_documents.length) {
        this.configurations.deleted_service_documents.forEach((item, index) => {
          formData.append(`deleted_service_documents[${index}]`, item);
        });
      }

      if (this.configurations.venue_service_documents.length) {
        this.configurations.venue_service_documents.forEach((item, index) => {
          if (item.name && item.document_type_id && item.file) {
            for (let key in item) {
              formData.append(
                `venue_service_documents[${index}][${key}]`,
                item[key]
              );
            }
          }
        });
      }

      for (let key in this.configurations.venue_service_configuration) {
        if (
          this.configurations.venue_service_configuration[key] != null &&
          this.configurations.venue_service_configuration[key] != ""
        ) {
          formData.append(
            `venue_service_configuration[${key}]`,
            this.configurations.venue_service_configuration[key]
          );
        }
      }
      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/configurations",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.gotoMembership();
            this.$store
              .dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueServiceId
              )
              .then(() => {
                this.$nextTick(() => {
                  this.$forceUpdate;
                });
              });
            this.hideLoader();
            this.showSuccess("Configuration successfully updated");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
          this.$store.dispatch(
            "loadConfigurationsByVenueServiceId",
            this.venueServiceId
          );
          this.$store.dispatch("loadVenueServices");
        });
    },
    gotoMembership() {
      this.$router.push({
        name: "Memberships",
      });
    },
  },
};
</script>

<style scoped>
.settings_table {
  width: 100%;
  text-align: center !important;
}
.settings_table th {
  text-align: center !important;
}
.settings_table td,
.settings_table th {
  border: 1px solid #ddd;
  padding: 8px;
}
.settings_table tr {
  background-color: #f2f2f2;
}
.settings_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}
.settings_table tr:hover {
  background-color: #ddd;
}
.settings_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
</style>
